import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Assistance: Shoulder Bridge 8-8-8 (shoulders on box or bench, weight on
hips)`}</p>
    <p>{`then,`}</p>
    <p>{`Power Clean 5-5-5-5\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`9-Thrusters (115/75)`}</p>
    <p>{`35-Double Unders`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      